import React, { useEffect } from "react";
import robotImage from "../assets/images/element/robot-1.png"; // Adjust the path as necessary

const OAuthCallback = () => {
  useEffect(() => {
    // Extract the authorization code from the URL
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    const error = urlParams.get("error");

    if (error) {
      console.error("OAuth Error:", error);
      alert(`OAuth Error: ${error}`);
      return;
    }

    if (code) {
      console.log("Authorization code received:", code);

      // Redirect to the mobile app with the code
      window.location.href = `myapp://oauth/callback?code=${code}`;
    } else {
      console.error("No authorization code in the URL");
    }
  }, []);

  return (
    <div style={{ textAlign: "center", marginTop: "20vh" }}>
      {/* Add the robot image */}
      <img
        src={robotImage}
        alt="Robot"
        style={{ width: "150px", height: "auto", marginBottom: "20px" }}
      />
      <h1 style={{ color: "#EE6C4D" }}>Redirecting...</h1>
      <p style={{ color: "#EE6C4D" }}>
        Please wait while we redirect you back to the app.
      </p>
    </div>
  );
};

export default OAuthCallback;
